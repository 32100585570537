.cart img{
    color: $primary;
}

.cart img,
.cart svg {
    height: 2rem;
    width: 2rem;
    position: relative;
    left: 8px;
}

.cart .cart-status{
    padding: 0 0.5em;
    font-size: 0.7em;
    line-height: 1.25em;
    border-radius: 10%;
    position: relative;
    left: -8px;
    top: -10px;
}
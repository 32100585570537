.is-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.is-print-only {
  display: none;
}

@media print {

  @page {
    size: letter;
    margin: 0.25in;
  }

  body {
    font-size: 16px;
    margin: 0;
    margin-inline: auto;
  }

  .navbar.is-fixed-top {
    position: static;
    z-index: 0;
  } 

  .card {
    break-inside: avoid;
  }

  .card-content .columns.is-multiline .column {
    width: 100%;
  }
  
  thead {
    break-after: avoid;
  }

  tfoot {
    break-before: avoid;
  }

  tr, th {
    break-inside: avoid;
  }

  .is-hidden-print {
    display: none;
  }

  .is-print-only {
    display:  block;
  }
}
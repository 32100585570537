@media only screen and (max-width: ($tablet - 1)) {

    /* Force table to not be like tables anymore */
    .table.is-vertical-mobile,
    .table.is-vertical-mobile thead,
    .table.is-vertical-mobile tbody,
    .table.is-vertical-mobile th,
    .table.is-vertical-mobile td,
    .table.is-vertical-mobile tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .table.is-vertical-mobile thead tr {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }   

    .table.is-vertical-mobile td:not(:has(img))::before {
        content: (attr(data-th)':');
        margin-right: 1rem;
    }
}
.card-image:has(.card-content):hover img {
    opacity: 0.05;
}

.card-image:hover .card-content {
    display: block;
    border-radius: 0;
}

.card-image .card-content {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
}

// .card-footer-item.is-loading {
//     position: absolute;
//     top: 0;
// }

.column .card {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.column .card-content,
.column .card-header {
    height: 100%;
}
.image.is-contain img{
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    scale: 95%;
}

.list-item-image figure{
    display: flex;
    justify-content: center;
    align-items: center;
}

// *** Image load error ********************************************************************************

img {
    text-align: center;
    position: relative;
}

img::before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('/assets/logo-nfe-black-512x512.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #dbdbdb;
    position: absolute;
    left: 0;
}

img::after {
    content: "\2639" " Image not found: " attr(alt);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

@media only screen and (max-width: ($tablet - 1)) {
    .image.is-128x128-touch {
        width: 128px;
        height: 128px;
    }
}